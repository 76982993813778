define(['app', '$console', 'elementViewed'], (app, $console, elementViewed) => {

  const fastTrackSwitch = () => {
    const component = {};
    component.elementViewed = elementViewed;

    const _config = {
      selectors: {
        toggle: '.fastTrackSwitch_toggle',
        productId: 'data-product-id',
        linkedProductId: 'data-linked-product-id',
        switchState: 'data-switch-enabled',
        infoMessage: '.fastTrackSwitch_infoMessage',
        index: 'data-index',
        responsiveProductListPage: 'responsiveProductListPage_fastTrackSwitch'
      },
      channels: {
        stateChange: 'fastTrackSwitch/stateChange',
        update: 'fastTrackSwitch/update',
        fastTrackInfoToggle: 'fastTrackInfoBox/toggle',
        DSTracking: 'columbo/track'
      }
    };

    const DS_TRACKING_SUBTYPE = 'fast_track_switch';
    const DS_TRACKING_TYPE = 'click';
    const DS_TRACKING_ATTR_NAME = 'fast_track';

    const GA_EVENT_CATEGORY = 'Fast Track Engagement';
    const GA_EVENT_ACTION = 'Clicked Fast Track Toggle';
    const GA_VIEW_EVENT_ACTION = 'Viewed Fast Track Toggle';

    const _init = (element, userDriven, publish) => {
      component.element = element;
      component.parent = component.element.parentNode;
      component.toggle = component.element.querySelector(component.config.selectors.toggle);
      component.productId = component.element.getAttribute(component.config.selectors.productId);
      component.linkedProductId = component.element.getAttribute(component.config.selectors.linkedProductId);
      component.switchState = component.element.getAttribute(component.config.selectors.switchState);
      component.index = component.element.getAttribute(component.config.selectors.index);
      component.infoMessage = component.element.querySelector(component.config.selectors.infoMessage);

      if (!userDriven) {
        app.subscribe(component.config.channels.update, component.updateFastTrackSwitch);
        component.elementViewed(component.element, component.fastTrackSwitchViewed);
      }

      if (publish) {
        component.publishSwitchStateInformation();
      }

      component.addEventListeners();
      return component;
    };

    const _addEventListeners = () => {
      if (component.toggle) {
        component.toggle.addEventListener('change', component.toggleStateChanged);
      }
    };

    const _toggleStateChanged = () => {
      if (component.infoMessage) {
        component.infoMessage.classList.toggle('show');
      }
      component.setCookie('fastTrackSwitchCookie',
        component.switchState === 'false' ? 'enableSwitch' : 'disableSwitch');

      if (component.parent
        && !component.parent.classList.contains(component.config.selectors.responsiveProductListPage)) {
        component.displayHideFastTrackInfoBox();
        component.getFastTrackSwitch(component.productId, true, true);
      } else {
        component.publishSwitchStateInformation(component.switchState === 'false');
      }
    };

    const _updateFastTrackSwitch = (productId) => {
      if (component.switchState === 'true') {
        if (component.linkedProductId !== '0') {
          component.getFastTrackSwitch(productId);
        } else if (component.productId !== productId) {
          component.getFastTrackSwitch(productId, true);
        }
      }
    };

    /**
     * Set cookie name, value and expiration date
     */
    const _setCookie = (name, value) => {
      let path = '/';
      let expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      document.cookie = name + '=' + value + '; expires=' + expirationDate.toUTCString() + '; path=' + path;
    };

    const _getFastTrackSwitch = (productId, stateChange, publish) => {
      let url = '/' + 'update.fasttrackupdate?productId=' + productId;
      if (stateChange) {
        url += '&stateChange=true';
      }
      app.ajax.get({
        url: url,
        success: publish === true ? component.updateAndPublish : component.fastTrackSwitchSuccessHandler,
        error: component.fastTrackSwitchErrorHandler
      });
    };

    const _updateAndPublish = (response) => {
      const newElement = component.constructNewFastTrackSwitch(response);
      component.init(newElement, true, true);
    };

    const _fastTrackSwitchSuccessHandler = (response) => {
      const newElement = component.constructNewFastTrackSwitch(response);
      component.init(newElement, true);
    };

    const _fastTrackSwitchErrorHandler = () => {
      $console.error('ERROR: Could not retrieve new fastTrackSwitch');
    };

    const _displayHideFastTrackInfoBox = () => {
      app.publish(component.config.channels.fastTrackInfoToggle, 'toggle');
    };

    const _constructNewFastTrackSwitch = (response) => {
      const parent = component.element.parentNode;
      parent.innerHTML = response;
      return parent.querySelector('[data-component=fastTrackSwitch]');
    };

    const _publishSwitchStateInformation = (switchStateForListPage) => {
      let switchState = component.switchState;
      if (typeof switchStateForListPage !== 'undefined' && switchStateForListPage !== null) {
        switchState = switchStateForListPage ? 'true' : 'false';
      }

      app.publish(component.config.channels.stateChange,
        component.productId,
        component.linkedProductId,
        switchState);
      app.publish(component.config.channels.DSTracking,
        DS_TRACKING_SUBTYPE, DS_TRACKING_TYPE, DS_TRACKING_ATTR_NAME, switchState);
      app.publish('tracking/record', GA_EVENT_CATEGORY, GA_EVENT_ACTION);
    };

    const _fastTrackSwitchViewed = () => {
      app.publish('tracking/record', GA_EVENT_CATEGORY, GA_VIEW_EVENT_ACTION);
    };

    component.config = _config;
    component.init = _init;
    component.addEventListeners = _addEventListeners;
    component.toggleStateChanged = _toggleStateChanged;
    component.updateFastTrackSwitch = _updateFastTrackSwitch;
    component.setCookie = _setCookie;
    component.getFastTrackSwitch = _getFastTrackSwitch;
    component.fastTrackSwitchSuccessHandler = _fastTrackSwitchSuccessHandler;
    component.fastTrackSwitchErrorHandler = _fastTrackSwitchErrorHandler;
    component.publishSwitchStateInformation = _publishSwitchStateInformation;
    component.displayHideFastTrackInfoBox = _displayHideFastTrackInfoBox;
    component.constructNewFastTrackSwitch = _constructNewFastTrackSwitch;
    component.updateAndPublish = _updateAndPublish;
    component.fastTrackSwitchViewed = _fastTrackSwitchViewed;

    return component;
  };

  return fastTrackSwitch;
});
